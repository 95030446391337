@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --animation-speed: 1000ms;
    --animation-speed-fast: 250ms;
    --default-cell-bg-color: theme('colors.white');
    --default-cell-border-color: theme('colors.black');
    --default-cell-text-color: theme('colors.black');
    --absent-cell-bg-color: theme('colors.slate.400');
    --correct-cell-bg-color: theme('colors.green.400');
    --present-cell-bg-color: theme('colors.yellow.400');
}

.dark {
    --default-cell-bg-color: theme('colors.slate.900');
    --default-cell-border-color: theme('colors.white');
    --default-cell-text-color: theme('colors.white');
    --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
    --correct-cell-bg-color: theme('colors.orange.400');
    --present-cell-bg-color: theme('colors.cyan.400');
}

.cell-fill-animation {
    animation: onTypeCell linear;
    animation-duration: 0.35s;
}

.cell-reveal {
    animation-duration: 0.35s;
    animation-timing-function: linear;
    animation-fill-mode: backwards;
}

.cell-reveal.absent {
    animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
    animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
    animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
    animation: offsetLetterFlip 0.35s linear;
    animation-fill-mode: backwards;
}

svg.cursor-pointer {
    transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
    transform: scale(1.2);
}

.jiggle {
    animation: jiggle linear;
    animation-duration: var(--animation-speed-fast);
}

.navbar {
    margin-bottom: 2%;
}

.navbar-content {
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
}

.right-icons {
    display: flex;
}

@keyframes revealAbsentCharCell {
    0% {
        transform: rotateX(0deg);
        background-color: var(--default-cell-bg-color);
        border-color: var(--default-cell-border-color);
        color: var(--default-cell-text-color);
    }
    50% {
        background-color: var(--default-cell-bg-color);
        border-color: var(--default-cell-border-color);
        color: var(--default-cell-text-color);
    }
    50.1% {
        background-color: var(--absent-cell-bg-color);
        border-color: var(--absent-cell-bg-color);
    }
    100% {
        transform: rotateX(180deg);
    }
}

@keyframes revealCorrectCharCell {
    0% {
        transform: rotateX(0deg);
        background-color: var(--default-cell-bg-color);
        border-color: var(--default-cell-border-color);
        color: var(--default-cell-text-color);
    }
    50% {
        background-color: var(--default-cell-bg-color);
        border-color: var(--default-cell-border-color);
        color: var(--default-cell-text-color);
    }
    50.1% {
        background-color: var(--correct-cell-bg-color);
        border-color: var(--correct-cell-bg-color);
    }
    100% {
        transform: rotateX(180deg);
    }
}

@keyframes revealPresentCharCell {
    0% {
        transform: rotateX(0deg);
        background-color: var(--default-cell-bg-color);
        border-color: var(--default-cell-border-color);
        color: var(--default-cell-text-color);
    }
    50% {
        background-color: var(--default-cell-bg-color);
        border-color: var(--default-cell-border-color);
        color: var(--default-cell-text-color);
    }
    50.1% {
        background-color: var(--present-cell-bg-color);
        border-color: var(--present-cell-bg-color);
    }
    100% {
        transform: rotateX(180deg);
    }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}

@keyframes onTypeCell {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.shadowed {
    text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
    0% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(-0.5rem, 0);
    }
    50% {
        transform: translate(0.5rem, 0);
    }
    75% {
        transform: translate(-0.5rem, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

/* Date picker */

.react-datepicker {
    @apply h-72;
}

.react-datepicker__input-container input {
    @apply form-input block w-full rounded border border-gray-300 bg-white text-base shadow-sm md:text-sm;
}

.react-datepicker-popper {
    @apply z-40 w-72 rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker-left {
    @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
    @apply absolute left-auto right-0 top-11 transform-none !important;
}

.react-datepicker__portal {
    @apply absolute right-0 top-12 z-10 w-72 transform-none rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker__month-container {
    @apply flex flex-col;
}

.react-datepicker__month {
    @apply flex flex-col;
}

.react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-gray-800 dark:text-gray-100;
}

.react-datepicker__week {
    @apply flex justify-around;
}

.react-datepicker__day-names {
    @apply flex justify-around text-center text-xs font-medium text-gray-400 dark:text-gray-500;
}

.react-datepicker__day-name {
    @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
    @apply absolute top-2;
}

.react-datepicker__navigation--previous {
    @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__navigation--next {
    @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__day {
    @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition dark:text-gray-200;
}

.react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
    @apply text-gray-300 dark:text-gray-600;
}

.react-datepicker__day--in-range {
    @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
    @apply bg-indigo-200;
}

.react-datepicker__day--selecting-range-start {
    @apply border-2 border-indigo-600 bg-white;
}

.react-datepicker__day--selecting-range-end {
    @apply border-2 border-indigo-600 bg-white;
}

.react-datepicker__day--selected {
    @apply bg-indigo-600 text-white dark:text-white;
}

.react-datepicker__day--range-start {
    @apply bg-indigo-600 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
    @apply bg-indigo-600 text-white hover:bg-white hover:text-gray-700;
}
